var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-flex",
        [
          _c(
            "v-row",
            { attrs: { justify: "start", align: "start" } },
            [
              _c("v-col", { attrs: { cols: "12", sm: "12", md: "6" } }, [
                _c("h3", [_vm._v("Notas")])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "12", md: "6" } },
                [
                  _c("OpinionEnrollmentRequestGradeInsert", {
                    attrs: { update: false }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-flex", [_c("OpinionEnrollmentRequestGradeTable")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }