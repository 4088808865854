<template>
  <div>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          outlined
          :color="update == true ? 'amber' : 'green'"
          x-small
          @click="dialog = true"
          >{{ update == true ? "" : "Nova"
          }}<v-icon :class="update == true ? '' : 'ml-2'" small>{{
            update == true ? "edit" : "add"
          }}</v-icon></v-btn
        >
      </template>
      <span>{{ update == true ?"Editar nota":"Inserir nova nota"}}</span>
    </v-tooltip>

    <v-dialog v-model="dialog" max-width="500" @keydown.esc="dialog = false">
      <v-card outlined>
        <v-card-title>
          <v-row justify="center" align="start">
            <v-col cols="12" class="text-center mb-1">
              <div class="title">{{ update==true?"Atualizar Nota":"Inserção de Nova Nota" }} </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                dense
                outlined
                type="text"
                label="nota"
                placeholder="digite a nota..."
                hint="nome da nota"
                persistent-hint
                :rules="rules"
                counter="3"
                v-model="grade.grade"
                @keypress.enter="
                  {
                    {
                      update == true
                        ? putAsyncOpinionGrade()
                        : postAsyncOpinionGrade();
                    }
                  }
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                dense
                outlined
                type="number"
                label="peso"
                placeholder="digite o peso..."
                hint="peso da nota, ex.: 1,2,3"
                persistent-hint
                :rules="rules"
                counter="3"
                v-model="grade.value"
                @keypress.enter="
                  {
                    {
                      update == true
                        ? putAsyncOpinionGrade()
                        : postAsyncOpinionGrade();
                    }
                  }
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-1">
          <v-row justify="center">
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn outlined color="red" @click="cancel()"
                >Cancelar<v-icon class="ml-2">cancel</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn
                @click="
                  {
                    {
                      update == true
                        ? putAsyncOpinionGrade()
                        : postAsyncOpinionGrade();
                    }
                  }
                "
                outlined
                color="green"
                :disabled="disabled || getDisabled"
                >{{ update == true ? "Atualizar" : "Inserir"
                }}<v-icon class="ml-2">{{
                  update == true ? "edit" : "check"
                }}</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" class="text-center">
              <label class="subtitle red--text">{{ errorMessage }}</label>
            </v-col>
            <v-col cols="12" class="mb-2 mt-1">
              <v-progress-linear
                indeterminate
                color="green"
                v-show="progress"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "OpinionEnrollmentRequestGradeInsert",
  props: {
    update: { type: Boolean, required: true },
    item: { type: Object, required: false, default: null },
  },
  data() {
    return {
      dialog: false,
      progress: false,
      disabled: false,
      grade: {
        grade: "",
        value: "",
      },
      errorMessage: "",
      rules: [
        (f) => !!f || "O campo não pode ser vazio",
        (f) =>
          !f || f.length < 3 || "O campo não ultrapassa o limite de caracteres",
      ],
    };
  },

  created() {
    if (this.item != null) {
      this.grade = this.item;
    }
  },

  computed: {
    getDisabled() {
      if (this.grade.grade == "" || this.grade.value == "") return true;
      else return false;
    },
  },

  watch: {
    item(newValue) {
      this.grade = newValue;
    },
  },

  methods: {
    ...mapActions("opinionEnrollmentRequestGrade", [
      "ActionPostOpinionGrade",
      "ActionPutOpinionGrade",
    ]),

    async postAsyncOpinionGrade() {
      this.disabled = true;
      this.progress = true;
      this.errorMessage = "";

      if (this.grade.grade == "" || this.grade.value == "") {
        this.errorMessage = "A nota não pode contar campos em branco!";
      } else {
        try {
          let response = await this.ActionPostOpinionGrade(this.grade);
          if (response.status == 201) this.cancel();
          else console.log(response.body);
        } catch (error) {
          console.error(error);
        } finally {
          this.disabled = false;
          this.progress = false;
        }
      }
    },

    async putAsyncOpinionGrade() {
      this.disabled = true;
      this.progress = true;
      this.errorMessage = "";

      if (this.grade.grade == "" || this.grade.value == "") {
        this.errorMessage = "A nota não pode contar campos em branco!";
      } else {
        try {
          let response = await this.ActionPutOpinionGrade(this.grade);
          if (response.status == 200) this.cancel();
          else this.errorMessage = response.bodyText;
        } catch (error) {
          console.error(error);
        } finally {
          this.disabled = false;
          this.progress = false;
        }
      }
    },

    cancel() {
      this.dialog = false;
      this.progress = false;
      if (this.item == null)
        this.grade = {
          grade: "",
          value: "",
        };
    },
  },
};
</script>

<style>
</style>