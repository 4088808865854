var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { right: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: { outlined: "", color: "red", "x-small": "" },
                          on: {
                            click: function($event) {
                              _vm.dialog = true
                            }
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-icon",
                        { staticClass: "ml-'", attrs: { small: "" } },
                        [_vm._v("delete")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("Apagar nota")])]
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.dialog = false
            }
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { outlined: "" } },
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center", align: "start" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center mb-1",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(" Remoção da nota "),
                            _c("strong", [_vm._v(_vm._s(_vm.item.grade))])
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "12" } }, [
                        _c("p", [
                          _vm._v(" Deseja realmente apagar a nota "),
                          _c("strong", [_vm._v(_vm._s(_vm.item.grade))]),
                          _vm._v("? O procedimento é irreversível!! ")
                        ]),
                        _c("p", [
                          _vm._v(
                            " Atenção!! Tal procedimento acarretará na remoção de todas as notas aplicadas pelos professores que utilizaram a nota "
                          ),
                          _c("strong", [_vm._v(_vm._s(_vm.item.grade))]),
                          _vm._v(
                            " em seus conceitos para solicitações de matrículas! "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "mb-1" },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "12", md: "6" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { outlined: "", color: "red" },
                              on: {
                                click: function($event) {
                                  return _vm.cancel()
                                }
                              }
                            },
                            [
                              _vm._v("Cancelar"),
                              _c("v-icon", { staticClass: "ml-2" }, [
                                _vm._v("cancel")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "12", md: "6" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                color: "green",
                                disabled: _vm.disabled
                              },
                              on: {
                                click: function($event) {
                                  return _vm.delAsyncOpinionGrade(_vm.item.id)
                                }
                              }
                            },
                            [
                              _vm._v("Apagar"),
                              _c("v-icon", { staticClass: "ml-2" }, [
                                _vm._v("delete")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          _c("label", { staticClass: "subtitle red--text" }, [
                            _vm._v(_vm._s(_vm.errorMessage))
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "mb-2 mt-1", attrs: { cols: "12" } },
                        [
                          _c("v-progress-linear", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.progress,
                                expression: "progress"
                              }
                            ],
                            attrs: { indeterminate: "", color: "green" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }