<template>
  <div>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          outlined
          color="red"
          x-small
          @click="dialog = true"
          ><v-icon class="ml-'" small>delete</v-icon></v-btn
        >
      </template>
      <span>Apagar nota</span>
    </v-tooltip>

    <v-dialog v-model="dialog" max-width="500" @keydown.esc="dialog = false">
      <v-card outlined>
        <v-card-title>
          <v-row justify="center" align="start">
            <v-col cols="12" class="text-center mb-1">
              <div class="title">
                Remoção da nota <strong>{{ item.grade }}</strong>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" sm="12">
              <p>
                Deseja realmente apagar a nota <strong>{{ item.grade }}</strong
                >? O procedimento é irreversível!!
              </p>
              <p>
                Atenção!! Tal procedimento acarretará na remoção de todas as
                notas aplicadas pelos professores que utilizaram a nota
                <strong>{{ item.grade }}</strong> em seus conceitos para
                solicitações de matrículas!
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-1">
          <v-row justify="center">
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn outlined color="red" @click="cancel()"
                >Cancelar<v-icon class="ml-2">cancel</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn outlined color="green" :disabled="disabled" @click="delAsyncOpinionGrade(item.id)"
                >Apagar<v-icon class="ml-2">delete</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" class="text-center">
              <label class="subtitle red--text">{{ errorMessage }}</label>
            </v-col>
            <v-col cols="12" class="mb-2 mt-1">
              <v-progress-linear
                indeterminate
                color="green"
                v-show="progress"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { mapActions } from "vuex";

export default {
  name: "OpinionEnrollmentRequestGradeDelete",
  props: {
    item: { type: Object, required: true },
  },

  data() {
    return {
      dialog: false,
      progress: false,
      disabled: false,
      errorMessage: "",
    };
  },

  created() {},

  methods: {
    ...mapActions("opinionEnrollmentRequestGrade", [
      "ActionDeleteOpinionGrade",
    ]),

    async delAsyncOpinionGrade(id){
      try{ 
        this.disabled = true;
        this.progress = true;
        let response = await this.ActionDeleteOpinionGrade(id);
        if(response.status == 200)
          this.cancel();
         else
          console.error(response);
      }
      catch(error){
        console.error(error);
      }
      finally{
        this.progress = false;
        this.disabled = false;
      }
    },

    cancel() {
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>