<template>
  <div>
      <v-data-table
        :headers="headers"
        :items="listGrades"
        item-key="id"
        :loading="loading"
        hide-default-footer
        loading-text="Buscando notas"
        no-data-text="Não foram encontradas notas cadastradas no sistema"
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-left">{{ props.item.grade }}</td>
            <td class="text-xs-left">{{ props.item.value }}</td>
            <td class="text-xs-left"><OpinionEnrollmentRequestGradeInsert :item="props.item" :update="true"></OpinionEnrollmentRequestGradeInsert></td>
            <td class="text-xs-left">
              <OpinionEnrollmentRequestGradeDelete :item="props.item"></OpinionEnrollmentRequestGradeDelete>
            </td>
          </tr>
        </template>
      </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import OpinionEnrollmentRequestGradeDelete from "./OpinionEnrollmentRequestGradeDelete"
import OpinionEnrollmentRequestGradeInsert from "./OpinionEnrollmentRequestGradeInsert";

export default {
    name: "OpinionEnrollmentRequestGradeTable",
    props: ["items"],
    components: { OpinionEnrollmentRequestGradeDelete, OpinionEnrollmentRequestGradeInsert },

    data() {
    return {
      loading: false,
      listGrades: [],
      headers: [
        {
          sortable: true,
          text: "Nota",
          value: "grade",
          align: "left"
        },
        {
          sortable: true,
          text: "Peso",
          value: "value",
          align: "left"
        },
        {
          sortable: false,
          text: "Editar",
          value: "edit",
          align: "left"
        },
        {
          sortable: false,
          text: "Excluir",
          value: "delete",
          align: "left"
        },
      ],
    };
  },

  created() {
    this.getAsyncAllOpinionGrade();
  },

  computed: {
    ...mapState("opinionEnrollmentRequestGrade", ["grades"]),
  },

  watch: {
    grades(newValue){
      this.listGrades = newValue;
    }
  },

  methods: {
    ...mapActions("opinionEnrollmentRequestGrade", [
      "ActionGetAllOpinionGrade",
    ]),

    async getAsyncAllOpinionGrade() {
      this.loading = true;
      try {
        await this.ActionGetAllOpinionGrade();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>

<style>

</style>