var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.listGrades,
          "item-key": "id",
          loading: _vm.loading,
          "hide-default-footer": "",
          "loading-text": "Buscando notas",
          "no-data-text": "Não foram encontradas notas cadastradas no sistema"
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(props) {
              return [
                _c("tr", [
                  _c("td", { staticClass: "text-xs-left" }, [
                    _vm._v(_vm._s(props.item.grade))
                  ]),
                  _c("td", { staticClass: "text-xs-left" }, [
                    _vm._v(_vm._s(props.item.value))
                  ]),
                  _c(
                    "td",
                    { staticClass: "text-xs-left" },
                    [
                      _c("OpinionEnrollmentRequestGradeInsert", {
                        attrs: { item: props.item, update: true }
                      })
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "text-xs-left" },
                    [
                      _c("OpinionEnrollmentRequestGradeDelete", {
                        attrs: { item: props.item }
                      })
                    ],
                    1
                  )
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }