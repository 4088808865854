<template>
  <v-container>
    <v-flex>
      <v-row justify="start" align="start">
        <v-col cols="12" sm="12" md="6">
          <h3>Notas</h3>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <OpinionEnrollmentRequestGradeInsert :update="false"></OpinionEnrollmentRequestGradeInsert>
        </v-col>
      </v-row>
    </v-flex>

    <v-flex>
      <OpinionEnrollmentRequestGradeTable></OpinionEnrollmentRequestGradeTable>
    </v-flex>
  </v-container>
</template>

<script>
import OpinionEnrollmentRequestGradeTable from "./OpinionEnrollmenRequestGradeTable";
import OpinionEnrollmentRequestGradeInsert from "./OpinionEnrollmentRequestGradeInsert";

export default {
  name: "OpinionEnrollmentRequestGrade",
  props: [],
  components: {
    OpinionEnrollmentRequestGradeTable,
    OpinionEnrollmentRequestGradeInsert,
  },
};
</script>

<style>
</style>